import React, { useEffect, useState } from 'react';
import { VIEWER_HOOKS } from '@wix/pricing-plans-common/hooks';

interface ClientSideRenderMarkProps {
  children: React.ReactNode;
}

export const ClientSideRenderMark: React.FC<ClientSideRenderMarkProps> = ({ children }) => {
  const [hasLoaded, setHasLoaded] = useState(false);

  useEffect(() => {
    setHasLoaded(true);
  }, []);

  return (
    <>
      {children}
      <div data-hook={hasLoaded ? VIEWER_HOOKS.WIDGET_RENDERED_MARK : undefined} style={{ display: 'none' }} />
    </>
  );
};
